
/*.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}*/
.tagLineHolder {
  background: #f6f6f6;
  font-size: 11px;
  padding: 7px 0;
  font-weight: bold;
}


h1 a, h2 a, h3 a, h4 a, h5 a, h6 a, h3 span, h1 a:hover, h2 a:hover, h3 a:hover, h4 a:hover, h5 a:hover, h6 a:hover {
  text-decoration: none;
  cursor: pointer;
}

a, h3 span {
    color: #5b7b95;
    text-decoration: none;
}


a:-webkit-any-link {
  cursor: pointer;
}
#map {
  width: 100%;
  /*height: 550px;*/
  align-self: center;
}

.mapContainer {
  margin: auto;
  width: 90%;
  height: 570px;
  border: 3px solid green;
  padding: 10px;
}
.searchForm, .positionPrecisionFilter {
    
    background: #718fa8 url(/public/search_bg_pattern.png) repeat;
    border-top: 1px solid #486175;
    border-bottom: 1px solid #486175;
    border-top: 1px solid #0a5c93;
    box-shadow: 0 1px 0 rgb(255 255 255 / 10%) inset, 0 -33px 16px -10px rgb(0 0 0 / 5%) inset, 0 33px 18px -10px rgb(250 250 250 / 10%) inset, 0 -10px 1px -10px rgb(0 0 0 / 30%) inset;
}
.clearTextSearch {
  position: relative;
  bottom: 40px;
  left: 30%;
}

.navbar-nav a {
  font-family: 'Open Sans';
    font-size: 15px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color:#718fa8;
    text-decoration: none;
}

.navbar-nav > a:hower {
  font-family: 'Open Sans';
    font-size: 15px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #8c8271;
    text-decoration: underline;
}

 a.active, a.active:hover {
  /*color: red;*/
  text-decoration: none;
  color: #8c8271;
  
}




body {
  background: #718fa8 url(/public/hylkytausta.jpg);
  background-repeat: repeat;
  background-size: 100%;
}

.frontPageLink a, span {
    color: #333333;
    font-family: 'Open Sans';
    font-size: 15px;
    font-weight: normal;
    cursor: pointer;
}
.form-inline {
  width: 100%;
}

.form-group {
  width: 90%;
}

/** map*/
.map-item-info {
  padding: 5px 5px 5px 5px;
}
.map-item-info .title {
  color: #333333;
  font-size: 12px;
  font-weight: bold;
}

/** Footer */
#colophon {
  background: #f9f9f9;
  border-top: 1px solid #cfcfcf;
  clear: both;
}
/*   
input[type=text] {
  width: 400px;
  margin: 8px 0;
  box-sizing: border-box;
}
textarea {
  width: 400px;
  margin: 8px 0;
  margin-left: 10px;

  box-sizing: border-box;
}*/

.hidden {
  height: 0px;
}
.updated-section {
  animation: pulse-animation 0.5s infinite;
}

@keyframes pulse-animation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.updated-height-grow {
  animation: pulse-animation-height-grow 0.5s infinite;
}

@keyframes pulse-animation-height-grow {
  /*0% {
    height: 1px;
  }
  25% {
    height: 142px;
  }
  50% {
    height: 285px;
  }
  75% {
    height: 428px; 
  }
  100% {
    height: 570px;
  }*/
  from{
    height: 0px;
  }
  to{
    height: 570px;
  }
}

.updated-height-shrink {
  animation: pulse-animation-height-shrink 0.5s infinite;
  
}
@keyframes pulse-animation-height-shrink {
 /* 0% {
    height: 570px;
  }
  25% {
    height: 428x;
  }
  50% {
    height: 285px;
  }
  75% {
    height: 142px; 
  }
  100% {
    height: 0px;
  }*/
  from{
    height: 570px;
  }
  to{
    height: 0px;
  }
}


@keyframes foo {
  from{
      height: 0px;
  }
  to{
    height: 570px;
  }
}