

#mysidebar {
  height: 100%;
}

#mysidebar .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
  color: #658095;
  text-decoration: none;
  background-color: rgba(113,143,168,0.94);
  padding: 10px;
  border: none;
  cursor: pointer;
}

.sidepanel {
  height: 100%;
  width: 0;
  z-index: 1;
  top: 0;
  left: 0;
  background: rgba(113,143,168,0.94);
  overflow-x: hidden;
  padding-top: 10px;
  transition: 0.5s;
}

.openbtn {
    font-size: 20px;
    cursor: pointer;
    background-color: rgba(101,128,149,0.85);
    color: white;
    padding: 10px 15px;
    border: none;
    position: absolute;
    bottom: 60px;
}

